import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image";


export default function ProjectsList({ projects}) {

  console.log(projects)

  return (
    <>
    {projects.map((project, index) => (
      <div key={project.id} className="col-lg-6 col-xxl-4 mb-5 mb-md-10 mb-lg-20" >
        <Link   to={project.uri} className="img-link mb-3" style={{ backgroundColor: project.projektDetails.color}}>
          <GatsbyImage image={getImage(project.featuredImage.node.localFile)} alt={project.title} className="img-browser" objectFit="contain" imgStyle={{ borderRadius: '5px' }} />
        </Link>
        
        <div className="ms-md-3 text-center_ fade-in" data-scroll="" >
        <Link   to={project.uri} className="-link">
          <h2  className="mb-2_" dangerouslySetInnerHTML={{ __html: project.title }} />
        </Link>
          <div className="small">{project.projektDetails.subtitle}</div>
        <div className="d-none tobedone">
          {project.tags?.nodes.map((t) => (
            <div className="border border-dark rounded-pill small px-2 py-1_ d-inline-block me-1 mb-1">
              {t.name}
              </div>
          ))}
          </div>
          
        </div>
  
        
      </div>
    ))}
    </>
  )
}