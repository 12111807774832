import React, {useEffect} from 'react'
import { graphql } from "gatsby"
import ProjectsList from '../components/ProjectsList'
import Splitting from "splitting";
import ScrollOut from "scroll-out";
//import Helmet from 'react-helmet';
import Seo from "../components/Seo"
import H1 from '../components/H1';

// markup
const ProjectsPage = ({data}) => {
  const {
    allWpPost: { nodes },
    wpPage: { title, content, seo },
  } = data

  useEffect(() => {

   // document.body.classList.add('projects');
    
    // do something
    Splitting();
    const scroll = ScrollOut({once:true});
    
    setTimeout(() => {
      scroll.update();
    },400);
    
    return () => {
      scroll.teardown();
      document.body.classList.remove('projects');
    }
  }, []);

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />

      <H1 title={title} data-scroll data-splitting="" />
      <div className="container-fluid mb-10 mb-md-20  fade-in" data-scroll dangerouslySetInnerHTML={{ __html: content }} />


      <div className="container-fluid">
        <div className="row">
          <ProjectsList projects={nodes} />
        </div>
      </div>
    </>
   )
}


export const query = graphql`
{
  wpPage(databaseId: {eq: 1402}) {
    title
    content
    seo {
      title
      metaDesc
    }
  }
  allWpPost(sort: {fields: [menuOrder], order: ASC}) {
    nodes {
      id
      title
      uri
      tags {
        nodes {
          slug
          databaseId
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 820
                transformOptions: {
                  cropFocus: CENTER
                  fit: CONTAIN
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      projektDetails {
        subtitle
        abstract
        color
      }
    }
  }
}
`

export default ProjectsPage
